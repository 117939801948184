<template>
    <div class="bgf">
        <div class="flex">
            <span class="fs_16 bold color_333">销售方纳税人信息</span>
            <div class="flex_end searchInput" v-if="rightUtils">
                <el-input placeholder="请输入姓名 / 身份证号 / 手机号" class="fs_14" ref="_search" v-model.trim="keywords" name="keywords" @keyup.enter="search">
                    <template #suffix>
                        <i class="el-icon-search" @click="search"></i>
                    </template>
                </el-input>
                <div style="margin-left: 30px">
                    <el-radio-group v-model="status" @change="statusChange">
                        <el-radio-button label="-1">全部</el-radio-button>
                        <el-radio-button label="0">待确认</el-radio-button>
                        <el-radio-button label="1">待开票</el-radio-button>
                        <el-radio-button label="2">已完成</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%" :row-style="rowStyle">
            <el-table-column label="编号" type="index" width="80"></el-table-column>
            <el-table-column label="姓名" width="80" prop="realname"></el-table-column>
            <el-table-column label="身份证号" prop="idcard"></el-table-column>
            <el-table-column label="手机号码" prop="mobile"></el-table-column>
            <el-table-column label="开票内容" show-overflow-tooltip prop="invoice_content"></el-table-column>
            <el-table-column label="开票含税金额（元）" prop="invoice_amount"></el-table-column>
            <el-table-column label="发票备注" show-overflow-tooltip prop="remark"></el-table-column>
            <el-table-column label="状态" width="80" v-if="rightUtils">
                <template #default="{ row }">
                    <p :class="row.status!=2? 'price_color':'sub_color'">{{ row.status_text }}</p>
                </template>
            </el-table-column>
        </el-table>
        <div class="bgf utils" v-if="amount.tax_fee">
            <div class="flex sun-price">
                <div class="flex_c">
                    <p class="fs_14 color_333 flex_c mr_30">
                        共提交
                        <span class="color">&nbsp; {{ tableData.length }} &nbsp;</span>
                        条数据
                    </p>
                    <p class="fs_14 color_333 flex_c mr_30">
                        综合费用&nbsp;
                        <span class="price_color fs_20">￥{{ amount.synthesis_fee }}</span>
                    </p>
                    <p class="fs_14 color_333 flex_c mr_30">
                        邮费&nbsp;
                        <span class="price_color fs_20">￥{{ amount.express_fee }}</span>
                    </p>
                </div>
                <div class="flex_c flex_end">
                    总计&nbsp;
                    <span class="price_color fs_32">￥{{ (Number(amount.tax_fee)*100 + Number(amount.service_fee)*100 + Number(amount.express_fee)*100)/100 }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {
			status: '-1',
			tableData: [],
			amount: {},
			keywords: ''
		}
	},
	props: {
		rightUtils: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		search() {
			var input = this.$refs._search
			input.blur()
			this.$parent.getDetail(this.status, this.keywords)
		},
		statusChange() {
			this.$parent.getDetail(this.status)
		}
	}
}
</script>

<style lang='less' scoped>
.btn {
    width: 117px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #a2aec7;
    margin-left: 20px;
    &.add {
        border-color: #1890ff;
    }
}
/deep/.el-table {
    margin-top: 20px;
    thead {
        color: #333;
    }
    /deep/thead th {
        background: #e7ecf6 !important;
        border-color: #fff;
    }
    .cell {
        text-align: center !important;
    }
    .err {
        .el-input__inner {
            // color: #ff4e4e;
            border-color: #ff4e4e;
        }
    }
    .el-input__inner {
        padding: 0;
        border-color: #fff;
        font-size: 12px;
        height: 24px;
        text-align: center;
    }
    .el-form-item.is-error .el-input__inner {
        border-color: #f56c6c;
    }
    .el-form-item--small.el-form-item {
        margin-bottom: 0;
    }
    .el-form-item--small .el-form-item__content,
    .el-form-item--small .el-form-item__label,
    .el-input--small .el-input__icon {
        line-height: 24px;
    }
}

/deep/.searchInput {
    .el-input {
        background: #f6f8fc;
        border-radius: 4px;
        overflow: hidden;
        width: 340px;
    }
    .el-input__inner {
        height: 40px;
        border: none;
        padding-right: 40px;
        background: #f6f8fc;
    }
    .el-icon-search {
        line-height: 40px;
    }
    .el-input__suffix {
        right: 15px;
    }
    .el-icon-search {
        font-size: 20px;
        cursor: pointer;
    }
    .el-radio-button--small .el-radio-button__inner {
        width: 80px;
        height: 40px;
        line-height: 40px;
        padding: 0;
    }
}

.utils {
    padding-bottom: 30px;
    // margin-top: 30px;
    .sun-price {
        border-bottom: 1px solid #e7ecf6;
        // border-top: 1px solid #e7ecf6;
        padding: 20px 40px;
    }
    .mr_30 {
        margin-right: 30px;
    }
    .submit {
        width: 200px;
        line-height: 24px;
        margin-top: 40px;
    }
}
</style>