<template>
   <div>
        <searchBar :searchInput="false"></searchBar>
        <div class="fs_16 color_333 title padding bgf mb_10">编号：{{ number }}</div>
        <taxpayer-info></taxpayer-info>
        <div class="padding bgf">
            <detail-table right-utils ref="detailTable"></detail-table>
            <el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load">
            </el-pagination>
        </div>
   </div>
</template>

<script>
import detailTable from './components/detail-table'
import taxpayerInfo from './components/taxpayer-info'
export default {
	name: 'TaxesDetail',
	components: { detailTable, taxpayerInfo },
	data() {
		return {
			id: '',
			number: '',
			limit: 10,
			page: 1,
			list: [],
			total: 0
		}
	},
	created() {
		this.id = this.$route.params.id
		this.number = this.$route.params.number
	},
	mounted() {
		this.getDetail()
	},
	methods: {
		getDetail(status='-1', keywords='') {
			this.$axios({
				url: `/ent/v3/taxes/item`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					pay_tax_id: this.id,
					keywords,
					status
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.list
					this.$refs.detailTable.tableData = res.data.list
					this.total = res.data.total
					this.finish = this.list.length === 0
				} else {
					this.finish = true
				}
			})
		},
		load() {
			this.page ++
		}
	}
}
</script>

<style lang='less' scoped>
.page {
    display: table;
    margin: 0 auto;
    margin-top: 30px;
}
</style>