<template>
    <div class="top bgf">
        <div class="title flex">
            <div class="fs_16 color_333 bold">
                <span>购买方纳税人信息</span>
            </div>
            <router-link to="/account?id=invoice" class="info_color fs_14" v-if="perfect && !isFull">完善信息 <span class="el-icon-arrow-right"></span></router-link>
        </div>
        <div class="info">
            <el-row>
                <el-col :span="6">
                    <div class="flex_c">
                        <span class="label sub_color w100">纳税人识别号：</span>
                        <span class="color_333">{{ ruleForm.creditcode || '/' }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="flex_c">
                        <span class="label sub_color">发票抬头：</span>
                        <span class="color_333">{{ ruleForm.company_name }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="flex_c">
                        <span class="label sub_color">发票类型：</span>
                        <span class="color_333">{{ ruleForm.invoice_type }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="flex_c">
                        <span class="label sub_color">联系电话：</span>
                        <span class="color_333">{{ ruleForm.phone || '/' }}</span>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <div class="flex_c mt_10">
                        <span class="label sub_color w100">开户银行：</span>
                        <span class="color_333">{{ ruleForm.bank_name || '/' }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="flex_c mt_10">
                        <span class="label sub_color">银行账户：</span>
                        <span class="color_333">{{ ruleForm.bank_account || '/' }}</span>
                    </div>
                </el-col>

                <el-col :span="12">
                    <div class="flex_c mt_10">
                        <span class="label sub_color">注册地址：</span>
                        <span class="color_333">{{ ruleForm.address || '/' }}</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="title flex">
            <div class="fs_16 color_333 bold">
                <span>服务方案</span>
            </div>
        </div>
        <div class="info">
            <el-row>
                <el-col :span="12">
                    <div class="flex_c mt_10">
                        <span class="color_999 w100">综合税率：</span>
                        <span class="color_333">{{ tax_rules.synthesis_fee+'%' || '/' }}</span>
                    </div>
                    <div class="fs_12 info_color">含增值税、城建税、教育附加、地方教育附加、印花税、所得税等</div>
                </el-col>

                <el-col :span="12">
                    <div class="flex_c mt_10">
                        <span class="color_999">快递费：</span>
                        <span class="color_333">{{ tax_rules.express_fee+'元' || '/' }}</span>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {
			ruleForm: {},
			tax_rules: {}
		}
	},
	props: {
		perfect: {
			default: false,
			type: Boolean
		}
	},
	created() {
		this.getInvoice()
		this.getRule()
	},
	computed: {
		isFull() {
			return this.ruleForm.creditcode && this.ruleForm.company_name && this.ruleForm.invoice_type!==false
            && this.ruleForm.bank_name && this.ruleForm.bank_account && this.ruleForm.phone && this.ruleForm.address
		}
	},
	methods: {
		getInvoice() {
			this.$axios({
				url: '/ent/v3/usercenter/company/invoice',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					this.ruleForm = res.data.invoice
					if(this.ruleForm.invoice_type === 0) {
						this.ruleForm.invoice_type = '增值税普通发票'
					}else if(this.ruleForm.invoice_type === 1) {
						this.ruleForm.invoice_type = '增值税专用发票'
					}else {
						this.ruleForm.invoice_type = '/'
					}
				}
			})
		},
		getRule() {
			this.$axios({
				url: '/ent/v3/taxes/rule',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					let rules = res.data.tax_rules
					let synthesis_fee = 0
					if(rules.service_fee && rules.rate) {
						synthesis_fee = Number(rules.service_fee)*100 + Number(rules.rate)*100
						synthesis_fee = (synthesis_fee/100).toFixed(2)
					}else {
						synthesis_fee = rules.service_fee || rules.rate
					}
					rules.synthesis_fee = synthesis_fee
					this.tax_rules = rules
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
.top {
    border-radius: 2px;
    font-size: 14px;
    border-bottom: 1px solid #e7ecf6;

    .title {
        padding: 30px 40px;
    }
    .info {
        padding: 20px 32px;
        border-top: 1px solid #e7ecf6;
        .label {
            width: 70px;
            display: inline-block;
            color: #999;
            line-height: 1.5;
            &.w100 {
                width: 100px;
            }
        }
        .flex_c {
            align-items: flex-start;
        }
        .color_333 {
            flex: 1;
            line-height: 1.5;
        }
    }
}
</style>